import React, { useState } from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import RippleButton from "./ripple-button"
// import VideoCategoryCard from "./video-category-card"
import VideoCategoryMenuGrid from "./video-category-menu-grid"

const VideoCategorySliderMenu = () => {
  const [categoryMenuOpen, setCategoryMenuOpen] = useState(false)
  const toggleCategoryMenu = e => {
    e.preventDefault()
    setCategoryMenuOpen(!categoryMenuOpen)
  }
  const closeCategoryMenu = e => {
    e.preventDefault()
    setCategoryMenuOpen(false)
  }

  const { wpCategories } = useStaticQuery(graphql`
    {
      wpCategories: allWpTermNode(
        sort: { fields: name, order: ASC },
        filter: { count: { gt: 0 } }
      ) {
        nodes {
          ... on WpCategory {
            name
            uri
            databaseId
          }
        }
      }
    }
  `)

  if (!wpCategories?.nodes || wpCategories.nodes === 0) return null

  return (
    <>
    <div className="video-slider">

      <RippleButton to="/c/fyi/" btnClass="btn video-category-button">
        FYI
      </RippleButton>

      <RippleButton to="/c/quick-news/" btnClass="btn video-category-button">
        Quick News
      </RippleButton>      

      <RippleButton to="/c/climate-crisis/" btnClass="btn video-category-button">
        Climate Crisis
      </RippleButton>

      <RippleButton to="/c/i-dont-get-it/" btnClass="btn video-category-button">
        I Don't Get It
      </RippleButton>

      <RippleButton to="/c/kidversation/" btnClass="btn video-category-button">
        Kidversation
      </RippleButton>

      <RippleButton to="/c/investigates/" btnClass="btn video-category-button">
        Investigates
      </RippleButton>

      <RippleButton to="/c/cooking/" btnClass="btn video-category-button">
        Cooking
      </RippleButton>

      <RippleButton to="/c/mental-health/" btnClass="btn video-category-button">
        Mental Health
      </RippleButton>

      <button
        type="button"
        className="btn video-category-button"
        onClick={toggleCategoryMenu}
      >
        View all&hellip;
      </button>
    </div>

    {
      categoryMenuOpen &&
        <VideoCategoryMenuGrid
          wpCategories={wpCategories}
          closeCategoryMenu={closeCategoryMenu} />
    }
    </>
  )
}

export default VideoCategorySliderMenu
