import React from "react"
import RippleButton from './ripple-button'

const VideoCategoryCard = ({ category, children }) => {
  const path = category?.uri ?? category.uri

  return (
    <RippleButton to={path} btnClass="btn video-category-button">
      {category.name}
    </RippleButton>
  )
}

export default VideoCategoryCard
