import * as React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import PageSection from '../components/page-section'
import HeroVideoCarousel from '../components/hero-video-carousel'
import VideoCategorySliderMenu from '../components/video-category-slider-menu'
import VideoSlider from '../components/video-slider'
//import PrivateRoute from "../components/privateRoute"
import ViewAllCategoryCard from '../components/view-all-category-card'

const IndexPage = ({ data }) => {
  const {
    heroPosts,
    latestPosts,
    postsInCategoryFYI,
    postsInCategoryQuickNews,
    postsInCategoryKidversation,
    postsInCategoryInvestigates,
    postsInCategoryIDGI,
    postsInCategoryCooking,
    postsInCategoryMentalHealth,
    postsInCategoryClimateCrisis,
    postsInCategoryDrawWithRob,
    postsInCategoryScience,
    postsInCategoryFirstCareers,
  } = data

let location = typeof window !== "undefined" ? window.location.pathname : ""

  return (

    <Layout isHomePage="true" pageTitle="Home">

      <HeroVideoCarousel videos={heroPosts}></HeroVideoCarousel>

      <div className="content-wrapper homepage">

      <PageSection
        title="Popular Categories"
        sectionClass="video-slider-padding popular-categories"
      >
        <VideoCategorySliderMenu />
      </PageSection>

      <PageSection
        title="New to First News"
        sectionClass="video-slider-padding"
      >
        <VideoSlider videos={latestPosts}></VideoSlider>
      </PageSection>

      {postsInCategoryFYI && postsInCategoryFYI?.nodes.length > 0 && (
        <PageSection
          title="FYI"
          titleLink="/c/fyi"
          titleIcon="right-arrow"
          sectionClass="video-slider-padding"
        >
          <VideoSlider videos={postsInCategoryFYI}>
            <ViewAllCategoryCard viewAllLink="/c/fyi" />
          </VideoSlider>
        </PageSection>
      )}

      {postsInCategoryQuickNews && postsInCategoryQuickNews?.nodes.length > 0 && (
        <PageSection
          title="Quick News"
          titleLink="/c/quick-news"
          titleIcon="right-arrow"
          sectionClass="video-slider-padding"
        >
          <VideoSlider videos={postsInCategoryQuickNews}>
            <ViewAllCategoryCard viewAllLink="/c/quick-news" />
          </VideoSlider>
        </PageSection>
      )}

      {postsInCategoryClimateCrisis && postsInCategoryClimateCrisis?.nodes.length > 0 && (
        <PageSection
          title="Climate Crisis"
          titleLink="/c/climate-crisis"
          titleIcon="right-arrow"
          sectionClass="video-slider-padding"
        >
          <VideoSlider videos={postsInCategoryClimateCrisis}>
            <ViewAllCategoryCard viewAllLink="/c/climate-crisis" />
          </VideoSlider>
        </PageSection>
      )}

      {postsInCategoryIDGI && postsInCategoryIDGI?.nodes.length > 0 && (
        <PageSection
          title="I Don’t Get It"
          titleLink="/c/i-dont-get-it"
          titleIcon="right-arrow"
          sectionClass="video-slider-padding"
        >
          <VideoSlider videos={postsInCategoryIDGI}>
            <ViewAllCategoryCard viewAllLink="/c/i-dont-get-it" />
          </VideoSlider>
        </PageSection>
      )}

      {postsInCategoryKidversation && postsInCategoryKidversation?.nodes.length > 0 && (
        <PageSection
          title="Kidversation"
          titleLink="/c/kidversation"
          titleIcon="right-arrow"
          sectionClass="video-slider-padding"
        >
          <VideoSlider videos={postsInCategoryKidversation}>
            <ViewAllCategoryCard viewAllLink="/c/kidversation" />
          </VideoSlider>
        </PageSection>
      )}

      {postsInCategoryInvestigates && postsInCategoryInvestigates?.nodes.length > 0 && (
        <PageSection
          title="Investigates"
          titleLink="/c/investigates"
          titleIcon="right-arrow"
          sectionClass="video-slider-padding"
        >
          <VideoSlider videos={postsInCategoryInvestigates}>
            <ViewAllCategoryCard viewAllLink="/c/investigates" />
          </VideoSlider>
        </PageSection>
      )}

      {postsInCategoryCooking && postsInCategoryCooking?.nodes.length > 0 && (
        <PageSection
          title="Cooking"
          titleLink="/c/cooking"
          titleIcon="right-arrow"
          sectionClass="video-slider-padding"
        >
          <VideoSlider videos={postsInCategoryCooking}>
            <ViewAllCategoryCard viewAllLink="/c/cooking" />
          </VideoSlider>
        </PageSection>
      )}

      {postsInCategoryMentalHealth && postsInCategoryMentalHealth?.nodes.length > 0 && (
        <PageSection
          title="Mental Health"
          titleLink="/c/mental-health"
          titleIcon="right-arrow"
          sectionClass="video-slider-padding"
        >
          <VideoSlider videos={postsInCategoryMentalHealth}>
            <ViewAllCategoryCard viewAllLink="/c/mental-health" />
          </VideoSlider>
        </PageSection>
      )}

      {postsInCategoryDrawWithRob && postsInCategoryDrawWithRob?.nodes.length > 0 && (
        <PageSection
          title="DrawWithRob"
          titleLink="/c/drawwithrob"
          titleIcon="right-arrow"
          sectionClass="video-slider-padding"
        >
          <VideoSlider videos={postsInCategoryDrawWithRob}>
            <ViewAllCategoryCard viewAllLink="/c/drawwithrob" />
          </VideoSlider>
        </PageSection>
      )}

      {postsInCategoryScience && postsInCategoryScience?.nodes.length > 0 && (
        <PageSection
          title="Science"
          titleLink="/c/science"
          titleIcon="right-arrow"
          sectionClass="video-slider-padding"
        >
          <VideoSlider videos={postsInCategoryScience}>
            <ViewAllCategoryCard viewAllLink="/c/science" />
          </VideoSlider>
        </PageSection>
      )}

      {postsInCategoryFirstCareers && postsInCategoryFirstCareers?.nodes.length > 0 && (
        <PageSection
          title="First Careers"
          titleLink="/c/first-careers"
          titleIcon="right-arrow"
          sectionClass="video-slider-padding"
        >
          <VideoSlider videos={postsInCategoryFirstCareers}>
            <ViewAllCategoryCard viewAllLink="/c/first-careers" />
          </VideoSlider>
        </PageSection>
      )}

      </div> {/* end of content-wrapper */}

    </Layout>
  )
}

export const query = graphql`
  fragment videoPostData on WpPost {
    videoUrl
    cardTitle
    cardImage
    cardImageSrcset
    featuredImage {
      node {
        sourceUrl
        srcSet
      }
    }
    title
    excerpt
    videoExcerpt
    slug
    id
    date
    featured
  }

  query HomePage
  {
    latestPosts: allWpPost(sort: {fields: date, order: DESC}, limit: 10) {
      nodes {
        ...videoPostData
      }
    }
    heroPosts: allWpPost(sort: {fields: date, order: DESC}, limit: 1) {
      nodes {
        ...videoPostData
      }
    }
    postsInCategoryFYI: allWpPost(
      filter: { categories: { nodes: { elemMatch: { slug: { eq: "fyi" } } } } }
      sort: { fields: date, order: DESC }
      limit: 9
    ) {
      nodes {
        ...videoPostData
      }
    }
    postsInCategoryQuickNews: allWpPost(
      filter: { categories: { nodes: { elemMatch: { slug: { eq: "quick-news" } } } } }
      sort: { fields: date, order: DESC }
      limit: 9
    ) {
      nodes {
        ...videoPostData
      }
    }    
    postsInCategoryKidversation: allWpPost(
      filter: { categories: { nodes: { elemMatch: { slug: { eq: "kidversation" } } } } }
      sort: { fields: date, order: DESC }
      limit: 9
    ) {
      nodes {
        ...videoPostData
      }
    }
    postsInCategoryInvestigates: allWpPost(
      filter: { categories: { nodes: { elemMatch: { slug: { eq: "investigates" } } } } }
      sort: { fields: date, order: DESC }
      limit: 9
    ) {
      nodes {
        ...videoPostData
      }
    }
    postsInCategoryIDGI: allWpPost(
      filter: { categories: { nodes: { elemMatch: { slug: { eq: "i-dont-get-it" } } } } }
      sort: { fields: date, order: DESC }
      limit: 9
    ) {
      nodes {
        ...videoPostData
      }
    }
    postsInCategoryCooking: allWpPost(
      filter: { categories: { nodes: { elemMatch: { slug: { eq: "cooking" } } } } }
      sort: { fields: date, order: DESC }
      limit: 9
    ) {
      nodes {
        ...videoPostData
      }
    }
    postsInCategoryMentalHealth: allWpPost(
      filter: { categories: { nodes: { elemMatch: { slug: { eq: "mental-health" } } } } }
      sort: { fields: date, order: DESC }
      limit: 9
    ) {
      nodes {
        ...videoPostData
      }
    }
    postsInCategoryClimateCrisis: allWpPost(
      filter: { categories: { nodes: { elemMatch: { slug: { eq: "climate-crisis" } } } } }
      sort: { fields: date, order: DESC }
      limit: 9
    ) {
      nodes {
        ...videoPostData
      }
    }
    postsInCategoryDrawWithRob: allWpPost(
      filter: { categories: { nodes: { elemMatch: { slug: { eq: "drawwithrob" } } } } }
      sort: { fields: date, order: DESC }
      limit: 9
    ) {
      nodes {
        ...videoPostData
      }
    }
    postsInCategoryScience: allWpPost(
      filter: { categories: { nodes: { elemMatch: { slug: { eq: "science" } } } } }
      sort: { fields: date, order: DESC }
      limit: 9
    ) {
      nodes {
        ...videoPostData
      }
    }
    postsInCategoryFirstCareers: allWpPost(
      filter: { categories: { nodes: { elemMatch: { slug: { eq: "first-careers" } } } } }
      sort: { fields: date, order: DESC }
      limit: 9
    ) {
      nodes {
        ...videoPostData
      }
    }
  }
`

export default IndexPage
