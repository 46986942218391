import React from "react"
import { Link } from 'gatsby'
import parse from "html-react-parser"

const HeroVideoCard = ({ video, children }) => {
  if ( !video ) return null

  const video_title = video.cardTitle ? video.cardTitle : video.title;

  let video_excerpt = video.videoExcerpt ? video.videoExcerpt : video.excerpt;
  video_excerpt = video_excerpt.replace(/(<([^>]+)>)/gi, "");

  return (
    <article className="hero-video-card">
      <div className='hero-card-header'>
        <div className='hero-card-image'>
          <Link to={`/${video.slug}`}>
            <img src={video.cardImage} srcSet={video.cardImageSrcset} alt={video_title} />
          </Link>
          <div className='play-icon-wrapper'>
            <span className='play-icon'></span>
          </div>
        </div>
      </div>
      <div className='hero-card-footer'>
        <div className='hero-card-title'>
          <Link to={`/${video.slug}`} className="h3">
            {video_title}
          </Link>
        </div>
        <p className="excerpt">{parse(video_excerpt)}</p>
      </div>
    </article>
  )
}

export default HeroVideoCard
