import React from "react"
import VideoCategoryCard from './video-category-card'

const VideoCategoryMenuGrid = ({ wpCategories, closeCategoryMenu }) => {

  return (
    <aside className="video-category-menu">

        <div className="video-category-grid-header">
          <h2 class="page-section-heading">All Categories</h2>

          <button
            type="button"
            className="btn close-category-menu"
            onClick={closeCategoryMenu}
            aria-label="Close"
          >
            <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><circle cx="12" cy="12" r="10"/><path d="m8 8 8 8m-8 0 8-8"/></svg>
            <span>Close</span>
          </button>
        </div>

        <div className="video-category-grid">
          {
            wpCategories.nodes.map((category, i) => {
              if (!category?.uri) return null

              return (
                <VideoCategoryCard
                  category={category}
                  key={i + category.uri} />
              )
            })
          }
        </div>

    </aside>
  )
}

export default VideoCategoryMenuGrid
