import React from "react"
import { Link } from 'gatsby'

const ViewAllCategoryCard = ({ viewAllLink, children }) => {
  return (
    <article className="video-card view-all">
      <Link to={viewAllLink} className="h4">
        <span>View All</span>
        <svg xmlns="http://www.w3.org/2000/svg" className="svg-right-arrow" viewBox="0 0 20 22" role="img" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="m2 2 8 9-8 9m8-18 8 9-8 9"></path></svg>
      </Link>
    </article>
  )
}

export default ViewAllCategoryCard
