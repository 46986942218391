import React from "react"
import HeroVideoCard from '../components/hero-video-card'

const HeroVideoCarousel = ({ videos, children }) => {
  return (
    <div className="hero-video-carousel">
      {
        videos && videos.nodes.map((video) => (
          <HeroVideoCard video={video} key={video.id}></HeroVideoCard>
        ))
      }
    </div>
  )
}

export default HeroVideoCarousel
