import React from "react"
import VideoCard from '../components/video-card'

const VideoSlider = ({ videos, format, children }) => {
  return (
    <div className="video-slider">
      {
        videos && videos.nodes.map((video) => (
          <VideoCard video={video} format={format} key={video.id}></VideoCard>
        ))
      }
      {children}
    </div>
  )
}

export default VideoSlider
